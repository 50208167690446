import { useState } from 'react'


const Item = ({ title, children }) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <div className="border rounded shadow-sm">
            <button
                type="button"
                aria-label="Open item"
                title="Open item"
                className="flex items-center justify-between w-full p-4 focus:outline-none"
                onClick={() => setIsOpen(!isOpen)}
            >
                <p className="text-lg font-medium">{title}</p>
                <div className="flex items-center justify-center w-8 h-8 border rounded-full">
                    <svg
                        viewBox="0 0 24 24"
                        className={`w-3 text-gray-600 transition-transform duration-200 ${isOpen ? 'transform rotate-180' : ''
                            }`}
                    >
                        <polyline
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeMiterlimit="10"
                            points="2,7 12,17 22,7"
                            strokeLinejoin="round"
                        />
                    </svg>
                </div>
            </button>
            {isOpen && (
                <div className="p-4 pt-0">
                    <p className="text-gray-700">{children}</p>
                </div>
            )}
        </div>
    );
};

export const FAQ = () => {
    return (
        <div class="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20 " style={{ fontFamily: 'Rubik, sans-serif' }}>
            <div class="max-w-xl sm:mx-auto lg:max-w-2xl">
                <div class="flex flex-col mb-16 sm:text-center">

                    <div class="max-w-xl md:mx-auto sm:text-center lg:max-w-2xl">
                        <h2 class="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto" style={{ fontFamily: 'Rubik, sans-serif' }}>
                            Frequently Asked Questions
                        </h2>
                        <p class="text-base text-gray-700 md:text-lg">
                            If you have any further questions email us at: help@sibinstitute.com
                        </p>
                    </div>
                </div>
                <div class="space-y-4">
                    <Item title="Who are the courses for?">
                        Our Arabic courses is ideal for beginners with no prior knowledge and anyone who would like to advance their proficiency in the arabic language. Our curriculum is based on the madinah books.
                    </Item>
                    <Item title="How are the courses structured?">
                        The courses are divided into modules that include video lessons, exercises. and downloadable resources like PDF's. Each module is designed to build on the previous one.
                    </Item>
                    <Item title="What are the duration of the courses?">
                        All our courses are self paced, and the duration it takes to finish varies from person to person.
                    </Item>
                    <Item title="Do I need any prior experience in Arabic?">
                       No prior experience in the Arabic language is needed to take our courses.
                    </Item>
                    <Item title="Will I recieve a certificate upon completion?">
                       Students who successfully complete any of our courses will recieve a certificate of completion which can be a great addition to your resume or portfolio.
                    </Item>
                </div>
            </div>
        </div>
    );
};

export default FAQ