import React from "react";
import { chakra, Box, Flex, Icon, Stack,Text} from "@chakra-ui/react";

function Feature(props) {
  return (
    <>
        <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-full-xl md:px-24 lg:px-8 lg:py-20 feature-section text-gray-200 ">
      <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
        <div>
        </div>
        <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-200 sm:text-4xl md:mx-auto">
          <span className="relative inline-block" >
            <Text fontFamily={"Abril Fatface, serif"} className="relative">Why Learn Arabic?</Text>
          </span>{' '}
         
        </h2>
        <p className="text-base text-gray-200 md:text-lg">
        </p>
      </div>
      <div className="grid gap-4 row-gap-5 sm:grid-cols-2 lg:grid-cols-4">


        <div className="flex flex-col justify-between p-5 border rounded shadow-sm dark-borders quran-image">
          <div>
            <div className="flex items-center justify-center w-16 h-16 mb-4">
             {/*  <svg
                className="w-12 h-12 text-deep-purple-accent-400"
                stroke="currentColor"
                viewBox="0 0 52 52"
              >
                <polygon
                  strokeWidth="3"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  fill="none"
                  points="29 13 14 29 25 29 23 39 38 23 27 23"
                />
              </svg> */}
            </div>
            <Text className="mb-2 font-semibold leading-5 text-lg " fontFamily={"Rubik, serif"}>Understand the quran</Text>
            <Text className="mb-3 text- text-gray-200" fontFamily={"Rubik, serif"}>
                Arabic is the language of the Qur'an. Understanding Arabic will deepen your relationship with the word of Allah.</Text>
          </div>
       {/*    <a
            href="/"
            aria-label=""
            className="inline-flex items-center font-semibold transition-colors duration-200 text-deep-purple-accent-400 hover:text-deep-purple-800"
          >
            Learn more
          </a> */}
        </div>
        <div className="flex flex-col justify-between p-5 border rounded shadow-sm dark-borders salah-image">
          <div>
            <div className="flex items-center justify-center w-16 h-16 mb-4">
              {/* <svg
                className="w-12 h-12 text-deep-purple-accent-400"
                stroke="currentColor"
                viewBox="0 0 52 52"
              >
                <polygon
                  strokeWidth="3"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  fill="none"
                  points="29 13 14 29 25 29 23 39 38 23 27 23"
                />
              </svg> */}
            </div>
            <Text className="mb-2 font-semibold leading-5 text-lg" fontFamily={"Rubik, serif"}>Greater Awareness in Ibāda</Text>
            <Text className="mb-3 text- text-gray-200" fontFamily={"Rubik, serif"}>
              The five daily prayers are offered in Arabic. You will be able to understand what is being recited and read during salah.
            </Text>
          </div>
   
        </div>
        <div className="flex flex-col justify-between p-5 border rounded shadow-sm dark-borders arabic-image">
          <div>
            <div className="flex items-center justify-center w-16 h-16 mb-4 ">
              {/* <svg
                className="w-12 h-12 text-deep-purple-accent-400"
                stroke="currentColor"
                viewBox="0 0 52 52"
              >
                <polygon
                  strokeWidth="3"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  fill="none"
                  points="29 13 14 29 25 29 23 39 38 23 27 23"
                />
              </svg> */}
            </div>
            <Text className="mb-2 font-semibold leading-5 text-lg" fontFamily={"Rubik, serif"}>Arabic is highly in demand </Text>
            <Text className="mb-3 text- text-gray-200" fontFamily={"Rubik, serif"}>
                Arabic is spoken by over 400 million people and counting. This can open up opportunities in multiple countries. 
            </Text>
          </div>
        {/*   <a
            href="/"
            aria-label=""
            className="inline-flex items-center font-semibold transition-colors duration-200 text-deep-purple-accent-400 hover:text-deep-purple-800"
          >
            Learn more
          </a> */}
        </div>
        <div className="flex flex-col justify-between p-5 border rounded shadow-sm dark-borders heritage-image">
          <div>
            <div className="flex items-center justify-center w-16 h-16 mb-4">
         {/*      <svg
                className="w-12 h-12 text-deep-purple-accent-400"
                stroke="currentColor"
                viewBox="0 0 52 52"
              >
                <polygon
                  strokeWidth="3"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  fill="none"
                  points="29 13 14 29 25 29 23 39 38 23 27 23"
                />
              </svg> */}
            </div>
            <Text className="mb-2 font-semibold leading-5 text-lg" fontFamily={"Rubik, serif"}>Rich Cultural Heritage</Text>
            <Text className="mb-3 text- text-gray-200" fontFamily={"Rubik, serif"}>
            Arabic opens a door to a rich and diverse cultural heritage. Learning Arabic allows individuals to explore and appreciate the contributions of Arab and Islamic civilizations to world culture, science, and knowledge.
            </Text>
          </div>
        {/*   <a
            href="/"
            aria-label=""
            className="inline-flex items-center font-semibold transition-colors duration-200 text-deep-purple-accent-400 hover:text-deep-purple-800"
          >
            Learn more
          </a> */}
        </div>

      </div>
    </div>
    

    
    </>
  );
}

export default Feature;
