import React from 'react'

import {
    Text,
  } from "@chakra-ui/react";
  


  export const WhySib = () => {
    return (
      <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20 ">
        <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
          <div>        
          </div>
          <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto">
            <span className="relative inline-block">                          
            <Text fontFamily={"Rubik, Serif"} className="text-5xl">Why Choose Sibawayhi Institute?</Text>
            </span>
          </h2>
          <Text  fontFamily={"Rubik, Serif"} className="text-base text-gray-900 md:text-lg">
          Choose Sibawayhi for expert instruction, immersive learning experiences, and personalized courses. 
          </Text>
        </div>
        <div className="grid max-w-screen-lg gap-8 lg:grid-cols-2 sm:mx-auto">
          <div className="grid grid-cols-2 gap-5">
            <img
              className="object-cover w-full h-56 col-span-2 rounded shadow-lg"
              src="https://images.unsplash.com/photo-1628962691167-27b7db9997e0?w=900&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8YXJhYmljJTIwY2FsbGlncmFwaHl8ZW58MHx8MHx8fDA%3D"
              alt=""
            />
            <img
              className="object-cover w-full h-48 rounded shadow-lg"
              src="https://images.unsplash.com/photo-1601480905449-90fca867ad37?w=900&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Nnx8YXJhYmljJTIwY2FsbGlncmFwaHl8ZW58MHx8MHx8fDA%3D"
              alt=""
            />
            <img
              className="object-cover w-full h-48 rounded shadow-lg"
              src="https://images.unsplash.com/photo-1564547889147-caa58fd02c0d?w=900&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OHx8YXJhYmljJTIwY2FsbGlncmFwaHl8ZW58MHx8MHx8fDA%3D"
              alt=""
            />
          </div>
          <div className="flex flex-col justify-center" style={{ fontFamily: 'Rubik, sans-serif' }}>
            <div className="pb-4 mb-4 border-b">
              <h6 className="mb-2 font-semibold leading-5">
              Flexible Learning Schedule
              </h6>
              <p className="text-sm text-gray-900">
              Learn Arabic at your own pace and on your own schedule. Online classes allow you to balance your studies with other commitments, providing the convenience to learn from anywhere at any time.
              </p>
            </div>
            <div className="pb-4 mb-4 border-b">
              <h6 className="mb-2 font-semibold leading-5">
              Access to Expert Instructors
              </h6>
              <p className="text-sm text-gray-900">
              Benefit from highly qualified and experienced instructors who provide personalized guidance and support. Our platforms feature interactive lessons, quizzes and videos to enhance your learning experience.
              </p>
            </div>
            <div>
              <h6 className="mb-2 font-semibold leading-5">
              Diverse Learning Resources
              </h6>
              <p className="text-sm text-gray-900">
              Gain access to a wide range of multimedia resources, including video lessons and interactive exercises along with PDFs and digital guides. These tools cater to various learning styles and help reinforce your understanding of the Arabic language.
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  };

  export default WhySib

