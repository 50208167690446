import './App.css';
import Navigation from './Components/Sections/Navigation';
import Header from './Components/Sections/Header';
import { ChakraProvider,  } from '@chakra-ui/react'
import { extendTheme} from "@chakra-ui/react";
import Feature from './Components/Sections/Feature';
import WhySib from './Components/Sections/WhySib';
import CallToAction from './Components/Sections/CallToAction';
import FAQ from './Components/Sections/FAQ';
import Footer from './Components/Sections/Footer';


const theme = extendTheme({
  fonts: {
    heading: "Abril Fatface, serif",
    paratext:"Rubik, sans-serif",
  },
});



function App() {
  return (
    <>
    <ChakraProvider >
 
    <div className="App">
    <Navigation/>
    <Header/>
    <Feature/>
    <WhySib/>
    <CallToAction/>
    <FAQ/>
    <Footer/>
     {/*  <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
      </header> */}

    </div>
    </ChakraProvider>
    </>
  );
}

export default App;
