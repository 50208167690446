import React from "react";
import { ReactNode } from "react";
import {
  Stack,
  Container,
  Box,
  Flex,
  Text,
  Heading,
  SimpleGrid,
} from "@chakra-ui/react";



function Header() {
  return (
    <Box id='features' bg={"#121a20"} position={"relative"}>
      <Flex
        flex={1}
        zIndex={0}
        display={{ base: "none", lg: "flex" }}
        backgroundImage="url(https://plus.unsplash.com/premium_photo-1675623429264-e6f60de79a4a?w=900&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8YXJhYmljJTIwY2FsbGlncmFwaHl8ZW58MHx8MHx8fDA%3D)"
        backgroundSize={"cover"}
        backgroundPosition="center"
        backgroundRepeat="no-repeat"
        position={"absolute"}
        width={"50%"}
        insetY={0}
        right={0}
      >

        <Flex
          bgGradient={"linear(to-r, #121a20 10%, transparent)"}
          w={"full"}
          h={"full"}
        />
      </Flex>
      <Container maxW={"7xl"} zIndex={10} position={"relative"}>
        <Stack direction={{ base: "column", lg: "row" }}>
          <Stack
            flex={1}
            color={"gray.400"}
            justify={{ lg: "center" }}
            py={{ base: 4, md: 20, xl: 10 }}
          >
            <Box mb={{ base: 8, md: 20 }}>
              <Text
                fontFamily={"heading"}
                fontWeight={700}
                textTransform={"uppercase"}
                mb={3}
                fontSize={"xl"}
                color={"yellow.500"}
              >
                Sibawayhi Institute
              </Text>
              <Heading
                color={"white"}
                fontSize={{ base: "3xl", md: "5xl" }}
                fontFamily="Abril Fatface, serif"
              >
                Your Gateway to Arabic Proficiency
              </Heading>
              <Text fontSize={"xl"} color={"gray.400"}></Text>
            </Box>


          {/*     <Text color={"gray.200"} fontSize="xl" fontFamily={"Rubik"}>
At our institution, we offer a unique Arabic learning experience that blends modern methods with cultural and religious insights. Our skilled instructors use interactive techniques to teach real-world communication. Students quickly learn to speak, listen, read, and write Arabic. We highlight the importance of Arabic as the language of the Qur'an, deepening students' understanding of Islamic texts, making learning meaningful and inspiring.
            </Text> */}

           <Text color={"gray.200"} fontSize="2xl" mb={0}> Your journey to mastering Arabic starts here!</Text> <Text color={"gray.200"} fontSize="2xl"> Join our immersive courses and achieve fluency with our expert guidance.</Text>


            {/* <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
              {stats.map((stat) => (
                <Box key={stat.title}>
                  <Text
                    fontFamily={"heading"}
                    fontSize={"3xl"}
                    color={"white"}
                    mb={3}
                  >
                    {stat.title}
                  </Text>
                  <Text fontSize={"xl"} color={"gray.400"}>
                    {stat.content}
                  </Text>
                </Box>
              ))}
            </SimpleGrid> */}
          </Stack>
          <Flex flex={1} />
        </Stack>
      </Container>
    </Box>
  );
}


const StatsText = ({ children }) => (
  <Text as={"span"} fontWeight={700}  fontFamily="Rubik" color={"white"}>
    {children}
  </Text>
);

const stats = [
  {
    title: "10+",
    content: (
      <>
        <StatsText>Best Cars</StatsText> We have the best cars.We have the best
        cars.We have the best cars. We have the best cars.
      </>
    ),
  },
  {
    title: "24/7",
    content: (
      <>
        <StatsText>Good Cars</StatsText> We have the best cars. We have the best
        cars. We have the best cars. We have the best cars.
      </>
    ),
  },
  {
    title: "13%",
    content: (
      <>
        <StatsText>Amazing Cars</StatsText> We have the best cars. We have the
        best cars. We have the best cars. We have the best cars.
      </>
    ),
  },
  {
    title: "250M+",
    content: (
      <>
        <StatsText>Good Cars</StatsText> We have the best cars. We have the best
        cars. We have the best cars. We have the best cars.
      </>
    ),
  },
];

export default Header;
